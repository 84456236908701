<template>
  <div class="base-input">
    <BaseIcon v-if="icon" :icon="icon" class="size-26 icon" />
    <input
      v-bind="$attrs"
      ref="inputElement"
      v-model="data"
      :type="$attrs?.type as string || 'text'"
    />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue?: string;
  icon?: string;
}>();

const emit = defineEmits(["update:modelValue"]);
const data = useVModel(props, "modelValue", emit);

const inputElement = ref<HTMLInputElement | null>(null);
defineExpose({ focus: () => inputElement.value?.focus() });
</script>

<style lang="scss" scoped>
.base-input {
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: $light-100;
  border: 1px solid $light-80;
  border-radius: $border-radius;
  color: $dark-50;
  overflow: hidden;
  height: 100%;
  padding: 1rem 0 1rem 1rem;

  input {
    border: 0;
    height: 100%;
    width: 100%;
    border-radius: $border-radius;
    color: $dark-100;
    font-size: 1.25rem;
    line-height: 1.5rem;
    flex: 1;
    margin-left: -3.5rem;
    padding-left: 3.5rem;

    padding-right: 1rem;

    z-index: 1;

    &::placeholder {
      color: $dark-50;
    }
  }

  .icon {
    z-index: 2;
  }

  input[type="search"] {
    background-color: $light-100;
    appearance: none;
  }
}
</style>
